import { Component,OnChanges, OnInit,ViewChild,ElementRef,OnDestroy,HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { NgForm,FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { error } from 'util';
import { operationService } from '../../_services/operation.service';
import { DataSharedService } from '../../_services/dataSharedService';
import * as url from '../../../environments/environment';
import { ProductModel,DependentProduct,ProductListModel } from '../../models/productmodel';
import { TilesOrder,TilesModel } from '../../models/tiles.model';
import { CartModel } from '../../models/cart.model';
import { DocumentTypeCodeEnum,ObjectTypeEnum,FolderCodeEnum } from '../../models/documenttypecode.enum';
import { BuildDmsJSONService } from '../../_services/builddmsjsonservice';
import { CartTypeEnum,PaymentMethodEnum } from '../../models/project.enum';
import { OrderDetails } from '../../models/ordermodel';
import { Order,Inscription } from '../../models/ordermodel';
import { UserSignUpModel,UserTokenModal,AdminLoginModel} from '../../models/user.model';
import { ComponentintractService } from '../../_services/conponentinteraction.service';
import { CustomerModel } from '../../models/user.model';
import { loginService } from '../../_services/login.service';
import { AddressModel } from '../../models/addressmodel';
/// <reference path="../../toastr.d.ts" />
import { PaymentModel,PaymentGatewayType } from '../../models/payment.model';
import { ProjectAdditionalModel } from '../../models/project.model';
import { Observable ,  concat } from 'rxjs';
import { ApplyVoucherModel } from '../../models/voucherorder.model';
@Component({
  selector: 'app-tiles-product',
  templateUrl: './tiles-product.component.html',
  providers:[BuildDmsJSONService,loginService]
})
export class TilesProductComponent implements OnInit,OnChanges,OnDestroy{

	public projectId:any;
	public customerId:any;
	public ProductGuid:any;
    public boolShowPaymentForm:boolean=false;
    @ViewChild('pageTop') pageTop:ElementRef;
    @ViewChild('paymentForm') paymentForm:ElementRef;
    public CartTypeEnum : typeof CartTypeEnum = CartTypeEnum;
    public PaymentMethodEnum : typeof PaymentMethodEnum = PaymentMethodEnum;
    public arrobjTilesProduct:Array<ProductModel> = new Array<ProductModel>();
    public arrCopyobjTilesProduct:Array<ProductModel> = new Array<ProductModel>();
    public objTilesProduct:ProductModel = new ProductModel();
    public objTilesOrder:TilesModel;
    public objCartDetails:CartModel = new CartModel();
    public arrobjOrderDetails:Array<OrderDetails> = new Array<OrderDetails>();
    public objOrderDetails:OrderDetails;
    public boolIsGuestUser:boolean=true;
    public objUser:any={};
    public subTotalChildOrders:any=0;
    public orderDeliveryCost:any=0;
    public boolIsInscriptionAvailable:boolean=true;
    public objUserSignUpModel:UserSignUpModel=new UserSignUpModel();
    public objAdminLoginModel:AdminLoginModel=new AdminLoginModel();
    public boolIsConfirmEmailEntered:boolean=false;
    public showAddressList:boolean=false;
    public objCustomerModel:CustomerModel = new CustomerModel();
    public productorderIndex:any;
    public authType:any;
    public objResourceJSON: any = {};
    public productindex:any;
    public strsiteKey:any;
    public paymentSuccessUrl:any;
    public paymentCancel:any;
    public DiscountCode:string;
    public captureDiscount:string;
    public routeUrl:any;
    public activeLanguageId:any;
    public replcaIndex:any=null;
    public boolSkipAddress:boolean=false; 
    public objPaymentModel:PaymentModel = new PaymentModel();
    public arrobjSelectedAddressModel:Array<AddressModel> = new Array<AddressModel>();
    public ObjectTypeEnum : typeof ObjectTypeEnum = ObjectTypeEnum;
    public userConsent:boolean;
    public LanguageCulture = sessionStorage.getItem("PGLanguageCulture");
    public DisplayWebTemplate = sessionStorage.getItem("DisplayWebTemplate");
    public angularForm:FormGroup = new FormGroup ({
            version: new FormControl(),
            stprofile: new FormControl(),
            mainamount: new FormControl(),
            billingtown: new FormControl(),
            customertown: new FormControl(),
            sitereference: new FormControl(),
            currencyiso3a: new FormControl(),
            billingstreet: new FormControl(),
            billingcounty: new FormControl(),
            orderreference: new FormControl(),
            customerstreet: new FormControl(),
            ruleidentifier: new FormControl(),
            billingpremise: new FormControl(),
            customercounty: new FormControl(),
            billingpostcode: new FormControl(),
            billingfirstname: new FormControl(),
            billinglastname:new FormControl(),
            billingtelephone: new FormControl(),
            customerpostcode: new FormControl(),
            customerfirstname: new FormControl(),
            customerlastname: new FormControl(),
            billingcountryiso2a: new FormControl(),
            customertelephone: new FormControl(),
            declinedurlredirect: new FormControl(),
            billingtelephonetype: new FormControl(),
            customertelephonetype: new FormControl(),
            successfulurlredirect: new FormControl(),
            locale: new FormControl()
    });
	constructor(private objFormBuilder: FormBuilder,private _loginService: loginService,private objComponentintractService:ComponentintractService,private objBuildDmsJSONService:BuildDmsJSONService, private objOperationService: operationService,private _router: Router, private route: ActivatedRoute){}
	ngOnInit(){
        this.objComponentintractService.setComponentdata({boolLaod:true});
        var arrUrlDetails = window.location.href.split('#');
        var baseUrl = arrUrlDetails[0];
        this.routeUrl = arrUrlDetails[1].split("/")[1];
        this.strsiteKey = url.sitekey;

        this.paymentSuccessUrl = arrUrlDetails[0]+"#/"+this.routeUrl+"/success";
        this.paymentCancel = arrUrlDetails[0]+"#/"+this.routeUrl+"/cancel";
		this.boolIsGuestUser = true;
        this.authType = atob(sessionStorage.getItem("AuthType"));
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                this.boolIsGuestUser = false;
            }
        }
        if( sessionStorage.getItem("UserId") ){
            this.objUser = {Id:sessionStorage.getItem("UserId")}
        }
		this.projectId = this.route.snapshot.params["projectId"];
        this.paymentGatewayList();
        this.getResourceJSON(sessionStorage.getItem("activeLanguage").toLowerCase());
        this.activeLanguageId = sessionStorage.getItem("activeLanguageId");
        this.getProjectTranslationDetail(this.projectId,this.ObjectTypeEnum.PROJECT,sessionStorage.getItem("languageCode"));
		this.getAllProduct(this.projectId,sessionStorage.getItem("currencyId"));
	}	
	ngOnChanges(){

	}
	ngOnDestroy(){
		// api/Product/?projectId=110451722&documentType=PRODUCT_IMAGE&imageType=THUMBNAIL
	}
    getResourceJSON(resourceFile){
        this.objOperationService.getLanguageJson(url.languageJsonUrl+resourceFile+".json").subscribe(
            data=>{
                this.objResourceJSON = data;
            }
        )
    }
    mappAddressToCart($event){
        this.objOperationService.put(url.mainUrl+"cart/UpdateCartAddress/?cartId="+this.objCartDetails.Id+"&billingId="+$event.billingId+"&shippingId="+$event.shippingId,{}).subscribe(
            data=>{
                this.getCartContents();
            }
        )
    }
    getLoggedInUserEmail(){

        var email:any="";
        if( sessionStorage.getItem("isLogedin") && JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){

            if( sessionStorage.getItem("Email") ){
                email = sessionStorage.getItem("Email");
            }           
        }    
        return email;
    }
    showAddTilesSpinner(objTilesProduct:ProductModel){

    }
    public projectGuid:any="";
    get skipShippingAddress(){
        return this.boolSkipAddress;
    }
	getCartContents(){
        this.projectGuid = "";
		this.customerId = 0;
		this.objCartDetails = new CartModel();
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
                this.customerId = sessionStorage.getItem("UserId");
            }
        }
        this.boolSkipAddress = true;
		this.objOperationService.get(url.mainUrl+"Cart/GetCartContents/?projectId="+this.projectId+"&customerId="+this.customerId).subscribe(
			data=>{
                this.showAddressList = true;
                this.objComponentintractService.setComponentdata({boolLaod:false});
                this.productindex = null;
                this.replcaIndex = null;
				this.objCartDetails = data;
                sessionStorage.setItem("orderCount",JSON.stringify(this.objCartDetails.Orders.length));
                let ordersreplica = this.objCartDetails.Orders.filter(item=>item.isDeliveryExist ==true  ||(item.DependentOrders.length > 0 && item.DependentOrders[0].isDeliveryExist == true));
				if( ordersreplica.length > 0 ){
                    this.boolSkipAddress = false;
                }

                this.objApplyVoucherModel.voucherCode = (this.objCartDetails.VoucherCode == undefined || this.objCartDetails.VoucherCode == null || this.objCartDetails.VoucherCode == "" )?"":this.objCartDetails.VoucherCode;
                this.DiscountCode = (this.objCartDetails.DiscountCode == undefined || this.objCartDetails.DiscountCode == null || this.objCartDetails.DiscountCode == "" )?undefined:this.objCartDetails.DiscountCode;
                this.captureDiscount = (this.objCartDetails.DiscountCode == undefined || this.objCartDetails.DiscountCode == null || this.objCartDetails.DiscountCode == "" )?undefined:this.objCartDetails.DiscountCode;
                
                this.boolIsVoucherApplied = false;
                if(this.objCartDetails.VoucherCode != undefined && this.objCartDetails.VoucherCode != null && this.objCartDetails.VoucherCode != "" ){
                    this.boolIsVoucherApplied = true;
                }
                this.preBuildOrderJSON();
                this.arrobjSelectedAddressModel = new Array<AddressModel>();
                if( this.objCartDetails.billingAddress != null ){
                    this.objCartDetails.billingAddress.Action = "edit";
                    this.arrobjSelectedAddressModel.push(Object.assign(new AddressModel(),this.objCartDetails.billingAddress))
                }
                if(  this.objCartDetails.shippingAddress != null){
                    this.objCartDetails.shippingAddress.Action = "edit";
                    this.arrobjSelectedAddressModel.push(Object.assign(new AddressModel(),this.objCartDetails.shippingAddress))
                }
                this.projectGuid = this.objCartDetails.project.Value;
                this.boolShowPaymentForm = true;
                this.getVoucherStockAvailability();
                this.buildPaymentForm(this.objCartDetails,this.objPaymentModel);
			},error=>{
                sessionStorage.setItem("isCartAvailable","false"); 
                sessionStorage.setItem("orderCount","0");
                this.boolShowPaymentForm = false;
                this.arrobjOrderDetails = new Array<OrderDetails>();
                this.productindex = null;
                this.objComponentintractService.setComponentdata({boolLaod:false});
			}
		)
	}
    get getCartType(){
        return this.objCartDetails.Type;
    }
    get getResourceMapping(){
        return this.objResourceJSON;
    }

    getUserConsentVal(){
       let userconsnt:string; 
       if(this.userConsent != undefined ){
        userconsnt = (this.userConsent)?'YES':'NO'
       };

       return userconsnt;
    };

    paymentGatewayList() {

        this.objPaymentModel.Sitereference
        this.objOperationService.get(url.bricsAPI+"PaymentgatewayConfig/GetAllPaymentConfiguration/?OrganizationId=" + sessionStorage.getItem("OrganisationId")).subscribe(
            data => {
                this.objPaymentModel = data[0];
            },error=> {
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    userSignUpLogin(objUserSignUpModel:UserSignUpModel,paymentMethod,target?){

        this.paymentmethod = paymentMethod;
        this.objComponentintractService.setComponentdata({boolLaod:true});
        let el = document.getElementById(target);
        el.scrollIntoView();
        objUserSignUpModel.Password = this.makeid(5);
        objUserSignUpModel.CreatedOn = new Date();
        objUserSignUpModel.IsVerificationRequired = false;

        this.objOperationService.post(url.umsUrl+"Admin/Customer/AddCustomerDetails",this.objUserSignUpModel).subscribe(
            data=>{
                this.getCustomerProfile(data.ResponseData,true);
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
                // this.proceedTocheckOut();
                if(error.json().ErrorCode == "DUPLICATE"){
                    if( this.authType == "SSO" ){
                        this.generateUserLoginToken(this.objUserSignUpModel.Email,this.objUserSignUpModel.Password,true,false,"User");
                    } else if ( this.authType == "NONSSO" ) {

                        // this.getCustomerProfile();
                    }
                }
                var objError = JSON.parse(error._body);
                if(objError.Type == "InvalidRequest"){

                    toastr.error(objError.Message)
                }
            }
        )
    }
    generateUserLoginToken(UserName,Password,isCheckOut,isValidationRequired,userType){
        let tokenData = "UserName="+UserName+"&Password="+Password+"&grant_type=password&scope="+"BRICKS "+sessionStorage.getItem("SessionId")+' Customer '+isValidationRequired;
        this._loginService.getToken(url.loginUrl + 'token', tokenData).subscribe(
            data=>{
                localStorage.setItem("access_token", data.access_token);
                sessionStorage.setItem("access_token", data.access_token);
                this.getUserDetails(isCheckOut);
            },error=>{

            }
        )
    }
    getUserDetails(isCheckOut:boolean){
        this._loginService.getLoginData(url.SSOAPI+'Login/GetUserDetail').subscribe(
            data=>{
                this.showAddressList = true;
                this.objUser = data;
                this.setSessionData(data);
                if( isCheckOut ){

                    this.transferGuestToCustomerCart(this.objUser,this.paymentmethod);
                } else {
                    
                }
            },error=>{

            }
        )
    }
    getCustomerProfile(customerId,isCheckOut){
        this.objOperationService.get(url.umsUrl+"Admin/Customer/GetCustomerProfileDetails/?customerid="+customerId).subscribe(
            data=>{
                this.showAddressList = true;
                this.objCustomerModel = data;
                this.objCustomerModel.Id = data.CustomerId
                this.objCustomerModel.IsGuestUser = false;
                this.objUser = this.objCustomerModel;
                this.setSessionData(this.objCustomerModel);
                this.transferGuestToCustomerCart(this.objCustomerModel,this.paymentmethod);
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    transferGuestToCustomerCart(objUser,paymentMethod){
        this.objOperationService.get(url.mainUrl+"cart/TransferGuestToCustomerCart/?customerId="+objUser.Id+"&projectId="+this.projectId+"&guestCartId="+this.objCartDetails.Id+"&cartReferenceNumber="+this.objCartDetails.CartReferenceNumber+"&PaymentMethod="+paymentMethod).subscribe(
            data=>{
                this.getCartContents();
            },error=>{
                this.getCartContents();
            }
        )
    }
    setSessionData(data):boolean{

        this.boolIsGuestUser = data.IsGuestUser;

        sessionStorage.setItem("UserId", data.Id ? data.Id : "");
        localStorage.setItem("UserId", data.Id ? btoa(data.Id) : "");

        sessionStorage.setItem("isLogedin", "true");
        localStorage.setItem("isLogedin", btoa("true"));

        sessionStorage.setItem("CurentCompanyCode", data.CurCompanyCode ? data.CurCompanyCode:"");
        localStorage.setItem("CurentCompanyCode",data.CurCompanyCode ? btoa(data.CurCompanyCode) : "");

        sessionStorage.setItem("IsGuestUser", data.IsGuestUser);
        localStorage.setItem("IsGuestUser", btoa(data.IsGuestUser));

        sessionStorage.setItem("IsAdmin", data.IsAdmin ? data.IsAdmin : "");
        localStorage.setItem("IsAdmin", data.IsAdmin ? btoa(data.IsAdmin) : "");

        
        if( !data.IsGuestUser ){

            sessionStorage.setItem("Email", data.Email ? data.Email:"");
            localStorage.setItem("Email", data.Email ? btoa(data.Email) :"");

            sessionStorage.setItem("FirstName", data.FirstName ? data.FirstName : data.FirstName);
            localStorage.setItem("FirstName", data.FirstName ? btoa(data.FirstName) : data.FirstName);

            sessionStorage.setItem("LastName", data.LastName != null? data.LastName :'');
            localStorage.setItem("LastName", btoa(data.LastName != null? data.LastName :''));
        }
        return true;
    }
    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
	buyTilesProduct(objTilesProduct:ProductModel,index){
        this.productindex = index;
		this.objTilesProduct = new ProductModel();
		this.objTilesProduct = objTilesProduct;
        this.objTilesProduct.orderQuantity = 0;
        this.ProductGuid = objTilesProduct.ProductGuid;
        let objectType:any;
        let isGuestUser:boolean = false;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) && JSON.parse(sessionStorage.getItem("IsGuestUser")) ){
                isGuestUser = true;
                objectType = ObjectTypeEnum.GUESTORDER;
            } else {
                isGuestUser = false;
                objectType = ObjectTypeEnum.ORDER;
            }
        } else {
            isGuestUser = true;
            objectType = ObjectTypeEnum.GUESTORDER;
        }
        var isReplica = false;
        var OrganizationId = Number(sessionStorage.getItem("OrganisationId"));
        /*sending product_coast as per discussion */
        var objTilesOrder = this.objBuildDmsJSONService.buildTilesOrderJSON("",this.objTilesProduct.ProductId,this.objTilesProduct.ParentProductId,this.objTilesProduct.ProductGuid,this.objTilesProduct.ProductName,this.objTilesProduct.ProductType,this.projectId,sessionStorage.getItem("UserId")?sessionStorage.getItem("UserId"):0,JSON.parse(sessionStorage.getItem("currencyId")),OrganizationId,this.objTilesProduct.ProductCost,"New",null,objTilesProduct.InstcriptionLineCount,1,this.CartTypeEnum.TILESCART,isGuestUser,false,this.objTilesProduct.IsParent,isReplica);
    	this.createCart(objTilesOrder);
	}
	addTilesproduct(objTilesProduct:ProductModel,cartid,index){
        this.productindex = index;
		this.objTilesProduct = new ProductModel();
		this.objTilesProduct = objTilesProduct;
        this.objTilesProduct.orderQuantity = 0;
        this.ProductGuid = objTilesProduct.ProductGuid;
        let isGuestUser:boolean = false;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) && JSON.parse(sessionStorage.getItem("IsGuestUser")) ){
                isGuestUser = true;
            } else {
                isGuestUser = false;
            }
        } else {
            isGuestUser = true;
        }
        /*sending product_coast as per discussion */
        var objTilesOrder = this.objBuildDmsJSONService.buildImageJSON(true,this.objTilesProduct.ProductId,this.objTilesProduct.IsParent,false,this.objTilesProduct.ParentProductId,"New",this.objTilesProduct.ProductCost,this.objTilesProduct.ProductGuid,1,this.objTilesProduct.ProductType,this.objTilesProduct.ProductName,null,null,this.objTilesProduct.InstcriptionLineCount);
    	this.addTilesOrder(objTilesOrder,isGuestUser);
	}

    checkGuestUser():boolean{
        let isGuestUser:boolean = false;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) && JSON.parse(sessionStorage.getItem("IsGuestUser")) ){
                isGuestUser = true;
            } else {
                isGuestUser = false;
            }
        } else {
            isGuestUser = true;
        }
        return isGuestUser;
    }
    emittOrderEvent($event:Order){

        if( $event ){
            this.showAddressList = false;
            this.scrollToSection(this.pageTop);
            this.getCartContents();
        }
    }
    
    public scrollToSection(specifiedSection:ElementRef):void {
        specifiedSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
	updateReplica(objTilesProduct:ProductModel,objOrderDetails:OrderDetails,cartid,replcaIndex){
        this.replcaIndex = replcaIndex;
        let isGuestUser:boolean = this.checkGuestUser();
        if( objOrderDetails.Quantity == 0 ){
            this.deleteTileOrder(objOrderDetails,isGuestUser);
            return true;
        }
        this.objTilesProduct = new ProductModel();
        this.objTilesProduct = objTilesProduct;
        this.objTilesProduct.orderQuantity = 0;
        this.ProductGuid = objTilesProduct.ProductGuid;
        /*sending product_coast as per discussion */
        var objTilesOrder:OrderDetails = this.objBuildDmsJSONService.buildImageJSON(true,this.objTilesProduct.ProductId,this.objTilesProduct.IsParent,true,this.objTilesProduct.ParentProductId,"Edit",this.objTilesProduct.ProductCost,this.objTilesProduct.ProductGuid,objOrderDetails.Quantity,this.objTilesProduct.ProductType,this.objTilesProduct.ProductName,null,null,this.objTilesProduct.InstcriptionLineCount);
        objTilesOrder.ParentOrderNumber = objOrderDetails.ParentOrderNumber;
        objTilesOrder.OrderNumber = objOrderDetails.OrderNumber; 
        this.updateTilese(objTilesOrder,isGuestUser);
    }
    addReplica(objTilesProduct:ProductModel,objOrderDetails:OrderDetails,cartid,replcaIndex){
        this.replcaIndex = replcaIndex;
		this.objTilesProduct = new ProductModel();
		this.objTilesProduct = objTilesProduct;
        this.objTilesProduct.orderQuantity = 0;
        this.ProductGuid = objTilesProduct.ProductGuid;
        let isGuestUser:boolean = false;
        if( sessionStorage.getItem("IsGuestUser") ){
            if( JSON.parse(sessionStorage.getItem("isLogedin")) && JSON.parse(sessionStorage.getItem("IsGuestUser")) ){
                isGuestUser = true;
            } else {
                isGuestUser = false;
            }
        } else {
            isGuestUser = true;
        }
        /*sending product_coast as per discussion */
        var objTilesOrder:OrderDetails = this.objBuildDmsJSONService.buildImageJSON(true,this.objTilesProduct.ProductId,this.objTilesProduct.IsParent,true,this.objTilesProduct.ParentProductId,"New",this.objTilesProduct.ProductCost,this.objTilesProduct.ProductGuid,objOrderDetails.DependentOrder.Quantity,this.objTilesProduct.ProductType,this.objTilesProduct.ProductName,null,null,this.objTilesProduct.InstcriptionLineCount);
		objTilesOrder.ParentOrderNumber = objOrderDetails.OrderNumber;
        this.addTilesOrder(objTilesOrder,isGuestUser);
	}
	placeTileProduct(action,cartid,isGuestUser,objectType){
		// this.objTilesOrder = new TilesModel();
	}
	addTilesOrder(objTilesOrder:OrderDetails,isGestUser){
		objTilesOrder.CartId = this.objCartDetails.Id;
		objTilesOrder.CustomerId = this.boolIsGuestUser ? 0:this.objUser.Id;
		this.objOperationService.post(url.mainUrl+"Order/AddTilesOrder?IsGuest="+isGestUser,objTilesOrder).subscribe(
			data=>{
                if( objTilesOrder.IsReplica ){
                    toastr.success(this.objResourceJSON.BriconomicsTilesProject.SuccessMessages.ReplicaOrderAdd);
                } else {
                    toastr.success(this.objResourceJSON.BriconomicsTilesProject.SuccessMessages.TilesOrderAdd);
                }
				this.getCartContents();
			},error=>{
				this.getCartContents();
			}
		)
	}
    updateTilese(objTilesOrder:OrderDetails,isGestUser){

        objTilesOrder.CartId = this.objCartDetails.Id;
        objTilesOrder.CustomerId = this.boolIsGuestUser ? 0:this.objUser.Id;
        this.objOperationService.put(url.mainUrl+"Order/EditTilesOrder?IsGuest="+isGestUser,objTilesOrder).subscribe(
            data=>{
                toastr.success("Replica quantity has been updated!");
                this.getCartContents();
            },error=>{
                this.getCartContents();
            }
        )
    }
    deleteTileOrder(objOrderDetails:OrderDetails,isGuestUser){
    	this.objOperationService.delete(url.mainUrl+"Order/DeleteTileOrder/?OrderId="+objOrderDetails.OrderId+"&IsGuest="+isGuestUser).subscribe(
    		data=>{
    			this.getCartContents();
    		},error=>{
                this.getCartContents();
    		}
		)
    }
    preBuildOrderJSON(){
    	this.subTotalChildOrders=0;
        this.boolIsInscriptionAvailable = true;
        this.arrobjOrderDetails = new Array<OrderDetails>();
        this.orderDeliveryCost=0;
        
        this.objCartDetails.Orders.forEach((value,index)=>{
        	let parentProductId:any=0;
            this.orderDeliveryCost = this.orderDeliveryCost + value.OrderDeliveryInclTax; 
            if( !value.isParentOrder ){

                this.subTotalChildOrders = this.subTotalChildOrders + (value.PriceInclTax - value.DiscountAmountInclTax);
            }
            let objOrderDetails:OrderDetails = new OrderDetails();
        	
        	if( value.ParentOrderNumber == null ){
	            objOrderDetails = this.objBuildDmsJSONService.buildImageJSON(true,value.ProductId,false,true,parentProductId,"New",(value.PriceInclTax - value.DiscountAmountInclTax),value.productDetails.ProductCode,value.Quantity,value.ItemType.Id,value.productDetails.ProductName,value.inscriptions);
	            objOrderDetails.ParentOrderNumber = value.ParentOrderNumber;
                objOrderDetails.FilePath = value.productDetails.ImagePath;
	            objOrderDetails.OrderNumber = value.OrderNumber;
	            objOrderDetails.OrderId = value.Id;
	            objOrderDetails.OrderDeliveryInclTax = value.OrderDeliveryInclTax;
	            objOrderDetails.OrderDeliveryExcTax = value.OrderDeliveryExcTax;
	            objOrderDetails.PrintTemplate = "";
	            objOrderDetails.ScreenTemplate = value.ScreenTemplate;
	            objOrderDetails.IsGuest = this.boolIsGuestUser;
	            objOrderDetails.customerId = this.boolIsGuestUser ? 0:this.objUser.Id;
                objOrderDetails.inscriptionCharactersPerLine = value.productDetails.InscriptionCharecterCount;
                objOrderDetails.orderAmount = (value.PriceInclTax - value.DiscountAmountInclTax)
	            if( value.DependentOrders.length > 0 ){
	            	let objDependenOrder:Order = value.DependentOrders[0];
	            	objOrderDetails.DependentOrder = this.objBuildDmsJSONService.buildImageJSON(true,objDependenOrder.ProductId,false,true,value.ProductId,"Edit",(objDependenOrder.PriceInclTax - objDependenOrder.DiscountAmountInclTax),objDependenOrder.productDetails.ProductCode,objDependenOrder.Quantity,objDependenOrder.ItemType.Id,objDependenOrder.productDetails.ProductName,objDependenOrder.inscriptions);
                    objOrderDetails.DependentOrder.OrderId = objDependenOrder.Id;
                    objOrderDetails.DependentOrder.orderAmount = objDependenOrder.PriceInclTax - objDependenOrder.DiscountAmountInclTax;
                    objOrderDetails.DependentOrder.OrderNumber = objDependenOrder.OrderNumber;
                    objOrderDetails.orderAmount = objOrderDetails.orderAmount+objOrderDetails.DependentOrder.orderAmount;
	            } else {
	            	objOrderDetails.DependentOrder = {Quantity:0,ProductId:0}
	            }
	            this.arrobjOrderDetails.push(objOrderDetails);
        	}
        });
    }
    updateInscription($event,objInscriptionLine:Inscription,orderindex){
        
        objInscriptionLine.InscriptionLine = objInscriptionLine.InscriptionLine.trim();
        this.boolIsInscriptionAvailable = true;
        this.objOperationService.put(url.mainUrl+"Order/UpdateOrderInscription?IsGuest="+this.boolIsGuestUser,objInscriptionLine).subscribe(
            data=>{
                this.productorderIndex = orderindex;
            },error=>{
                
                var objError = JSON.parse(error._body);
                if(objError.Type == "InvalidRequest"){

                    toastr.error(objError.Message)
                }
            }
        )
    }
    isInscriptionAvailable(objOrder:OrderDetails){
        
        var inscAvailFlag:boolean=false;
        objOrder.inscriptionDetail.forEach((value,index)=>{
            if( value.InscriptionLine.trim() != "" ){
                inscAvailFlag = true;
            }
        });
        if( this.boolIsInscriptionAvailable ){

            if( inscAvailFlag ){
                this.boolIsInscriptionAvailable = true;
            } else {

                this.boolIsInscriptionAvailable = false;
            }
        }
        return inscAvailFlag;
    }
    increaseProductQuantity(dependentProduct,objTilesProduct:ProductModel){
    	if( objTilesProduct.StockQuantity == "Unlimited" ){

    	} else {
    		if( objTilesProduct.StockQuantity == 0 ){
    			toastr.warning("Out of stock");
                return false;
    		}
    		if( objTilesProduct.StockQuantity > dependentProduct.Quantity ){
        		dependentProduct.Quantity = dependentProduct.Quantity + 1;
    		} else {
        		toastr.warning("You cannot add more than "+objTilesProduct.StockQuantity+" items");
    		}
    	}
    }
    decreaseProductQuantity(dependentProduct,objTilesProduct){
    	if(dependentProduct.Quantity > 0 ){
    		dependentProduct.Quantity = dependentProduct.Quantity - 1;
    	}
    }
	createCart(objTilesOrder:TilesModel){
		this.objOperationService.post(url.mainUrl+"Cart/CreateTilesCart",objTilesOrder).subscribe(
			data=>{
                sessionStorage.setItem("isCartAvailable","true");
                toastr.success(this.objResourceJSON.BriconomicsTilesProject.SuccessMessages.TilesOrderAdd);
                if( this.boolIsGuestUser ){

                    this.setSessionData({IsGuestUser:true,});
                }
				this.getCartContents();
			},error=>{
				this.getCartContents();
			}
		)
	}
    public inscriptionOrder:Array<Inscription> = new Array<Inscription>();
    public objDependentProduct:DependentProduct = new DependentProduct();
    public productTemplateURL:any="";
    public appendedHtml:any;
    getInscriptedImage(order:OrderDetails){
        this.inscriptionOrder = order.inscriptionDetail;
        this.productTemplateURL = order.ScreenTemplate;
        this.getTemplates(order.OrderId,this.objCartDetails.Id,this.objCartDetails.project.Value);
        $("#kstemppreview").modal("show");
    }
    getTemplates(orderId,cartId,projectGuid){
        this.objOperationService.get(url.mainUrl+"cart/GetPreviewImage/?orderId="+orderId+"&cartId="+cartId+"&IsGuest="+this.boolIsGuestUser+"&ProjectGuid="+projectGuid).subscribe(
            data=>{
                this.appendedHtml = data.ResponseData;
            },error=>{

            }
        )
    }
    closeScreenTemplates(){
        $("#kstemppreview").modal("hide");
        this.appendedHtml = "";
    }
	getProductDetails(){
        this.objOperationService.get(url.mainUrl + 'Product/?ProductGuid=' + this.ProductGuid+"&CurrentCurrencyId="+sessionStorage.getItem("currencyId")).subscribe(
            data=>{
               
            },error=>{

            }
        )
    }
	getCurrentCurrencyIcon(){
        var strClass:any;
        if( sessionStorage.getItem("currencyIcon") ){
            strClass = sessionStorage.getItem("currencyIcon");
        }
        return strClass;
    }
    getCurrentCurrency(){
        var CurrencyCode:any;
        if( sessionStorage.getItem("CurrencyCode") ){
            CurrencyCode = sessionStorage.getItem("CurrencyCode");
        }
        return CurrencyCode;
    }
    public boolSubmit:boolean=false;
    proceedToPayment(){
        this.boolSubmit = false;
        $("#logdelete").modal("show");
    }
    stockAvalibalityCheck(){

        $("#logdelete").modal("hide");
        this.objComponentintractService.setComponentdata({boolLaod:true});
        this.objOperationService.get(url.bricsAPI+"Product/IsPMStockAvailable/?projectGuid="+this.projectGuid).subscribe(
            data=>{
                if( data == true ){
                    if( this.objCartDetails.PaymentMethod == this.PaymentMethodEnum.OffLinepayment ){

                        this._router.navigate([this.routeUrl+'/success/'+this.objCartDetails.Id+"/"+this.objCartDetails.CartGuid]);
                    } else if( this.objCartDetails.PaymentMethod == this.PaymentMethodEnum.OnlinePayment ){

                        this.updatePaymentStatus(this.objCartDetails.CartGuid,this.objCartDetails.cartTotalAmount,this.angularForm.getRawValue());
                    }
                } else {
                    this.objComponentintractService.setComponentdata({boolLaod:false});
                    toastr.warning(this.objResourceJSON.Briconomics.Header.WallSoldOut);
                }
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    public objTimer:any;
    setTimer(){

        // set showloader to true to show loading div on view
        this.objTimer     = Observable.timer(10000); // 10000 millisecond means 5 seconds
        var subscription = this.objTimer.subscribe(() => {
            this.objComponentintractService.setComponentdata({boolLaod:false});
        });
    }   
    updatePaymentStatus(cartGuid,cartTotalAmount,objForm){
        this.objOperationService.put(url.bricsAPI+"cart/UpdatePaymentStatus/?CartGuid="+cartGuid+'&userConsent='+this.getUserConsentVal(), objForm).subscribe(
            data=>{
                if( cartTotalAmount > 0 ){
                    this.setTimer();
                    this.paymentForm.nativeElement.submit(); 
                } else if (cartTotalAmount == 0) {
                    this._router.navigate([this.routeUrl+'/success/'+this.objCartDetails.Id+"/"+this.objCartDetails.CartGuid]);
                } else {
                    toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.CartOrderError);
                    this.getCartContents();
                }
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
                toastr.error(error.json().Message);
            }
        )
    }
    public deleteModelName:string="tilesOrder";
	getAllProduct(projectId,currencyId){
		this.arrobjTilesProduct = [];
		this.arrCopyobjTilesProduct = [];
		this.objOperationService.get(url.bricsAPI+"Product/GetTilesProduct/?projectId="+projectId+"&imageType=null&CurrentCurrencyId="+currencyId).subscribe(
			data=>{
                data.sort(function(a, b){return a.ProductCost - b.ProductCost});
				// this.arrCopyobjTilesProduct = data;
                this.arrCopyobjTilesProduct = data.filter(res => res.Published == true);
				this.arrobjTilesProduct = this.arrCopyobjTilesProduct.filter(item=>item.ProductTypeCode == "TILE" && item.IsParent == 1);
                this.getCartContents();
			},error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
			}
		)
	}
    getProductDescriptionByLanguage(objProductModel:ProductModel){
        var localLanguageDescription = JSON.parse(objProductModel.Description);
        return localLanguageDescription[this.defaultActiveLanguage];
    }
    public languageCode:any;
    public objProjectAdditionalModel:ProjectAdditionalModel=new ProjectAdditionalModel();
    
    getProjectTranslationDetail(objectId,objectType,languageCode,strType?){
        this.languageCode = sessionStorage.getItem("languageCode");

        

        this.objOperationService.get(url.mainUrl+"Project/GetProjectTranslationDetail/?projectId="+objectId+"&LanguageCode="+this.languageCode).subscribe(
            data=>{
                this.objProjectAdditionalModel = data;
                if( strType == 'tc' ){
                    $("#terms-condition").modal("show");
                }
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    public boolIsStockAvailble:boolean=true;
    public boolIsVoucherApplied:boolean=false;
    getVoucherStockAvailability(){
        this.objOperationService.get(url.mainUrl+"Product/VoucherStockAvailability/?projectId="+this.projectId).subscribe(
            data=>{
                if( data.StockAvailabilityStatus == "Fail" || data.StockAvailabilityStatus == "OutOfStock" ){

                    this.boolIsStockAvailble = this.boolIsVoucherApplied;
                } else {
                    this.boolIsStockAvailble = true;
                }
            },error=>{

            }
        )
    }
    public boolIsAdminUser:boolean=false;
    checkForAdminuser(){
        this.objOperationService.get(url.mainUrl+"Cart/IsOrgAdmin/?emailId="+this.objUserSignUpModel.Email).subscribe(
            data=>{
                this.boolIsAdminUser = data;

            },error=>{

            }
        )
    }
    public paymentmethod:any;
    loginAdmin(paymentmethod){
        this.paymentmethod = paymentmethod;
        this.objOperationService.get(url.mainUrl+"Cart/ValidateForOrgAdmin/?emailId="+this.objUserSignUpModel.Email+"&password="+btoa(this.objUserSignUpModel.Password)).subscribe(
            data=>{
               if( data ){
                   let objUserSignUpModel:UserSignUpModel = new UserSignUpModel();
                   objUserSignUpModel = this.objUserSignUpModel;
                   objUserSignUpModel.Email = this.objAdminLoginModel.CustomerEmail;
                   this.userSignUpLogin(objUserSignUpModel,paymentmethod,'target')
               } 
            },error=>{

            }
        )
    }

    get getEmailPattern(){

        const regex = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/;
        var objEmailRegExp = new RegExp(regex, "i");
        return objEmailRegExp;
    }
    get checkValidation(){

        var isValid:boolean=true; 

        if( this.objCartDetails.IsAccepted == false ){
            isValid = false;
        }
        return isValid;
    }
    updateTCIsAccepted($event){
        var isGusetUser:boolean=false;
        var isAccepted:boolean;
        if( this.objCartDetails.Id != undefined && this.objCartDetails.Id != ""  ){

            isAccepted = this.objCartDetails.IsAccepted;
            isGusetUser = this.checkGuestUser();
            this.objOperationService.put(url.mainUrl+"Cart/UpdateIsAccepted/?IsAccepted="+isAccepted+"&CartId="+this.objCartDetails.Id+"&IsGuest="+isGusetUser,{}).subscribe(
                data=>{
                    
                },error=>{

                }
            )
        }
    }
    get defaultActiveLanguage(){
        return sessionStorage.getItem("DefaultLangauge");
    }

    get isValidAddress(){

        var isValid:boolean=true;
        let ordersreplica = this.objCartDetails.Orders.filter( item=>item.isDeliveryExist ==true ||  (item.DependentOrders.length > 0 && item.DependentOrders[0].isDeliveryExist == true));
        if( ordersreplica.length > 0 ){
            if( this.objCartDetails.shippingAddress == null ){
               isValid = false; 
            }
        }
        return isValid;
    }
    public objApplyVoucherModel:ApplyVoucherModel = new ApplyVoucherModel();
    voucherApply(){

        this.objApplyVoucherModel.cartId = this.objCartDetails.Id
        this.objApplyVoucherModel.date = new Date();
        this.objApplyVoucherModel.projectId = this.projectId;

        let paymentJson:any = {
            "StringParameter": [
            window.location.href
            ]
        }
        this.objOperationService.post(url.mainUrl+"Voucher/ApplyVoucherToCart/?Action=Apply",this.objApplyVoucherModel).subscribe(
            data=>{
                toastr.success(this.objResourceJSON.Briconomics.PhotoUploadWizard.Success.VoucherApplied);
                this.getCartContents();
            },error=>{
                
                /*If Voucher Is InCorrect*/
                if(error.json().ErrorCode == "INCORRECTVOUCHER"){
                    toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.IncorrectVoucherCode);
                }

                /*If Voucher Is InCorrect*/
                if(error.json().ErrorCode == "VOUCHEREXPIRED"){
                    toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.VoucherExpired);
                }

                /*If Voucher Is Redeemed*/
                if(error.json().ErrorCode == "REDEEMEDVOUCHER"){
                    toastr.error(this.objResourceJSON.Briconomics.PhotoUploadWizard.Error.RedeemedVoucherCode);
                }
            }
        )
    }
    removeVoucher(){

        this.objApplyVoucherModel.cartId = this.objCartDetails.Id
        this.objApplyVoucherModel.date = new Date();
        this.objApplyVoucherModel.projectId = this.projectId;

        let paymentJson:any = {
            "StringParameter": [
            window.location.href
            ]
        }
        this.objOperationService.put(url.mainUrl+"Voucher/RemoveVoucher/?Action=Remove",this.objApplyVoucherModel).subscribe(
            data=>{
                toastr.success(this.objResourceJSON.Briconomics.PhotoUploadWizard.Success.VoucherRemoved);
                this.getCartContents();
            },error=>{
                /*Voucher expiries and other error condition should check */
            }
        )
    }

    applyDiscount(action){
        this.objOperationService.post(url.mainUrl+"Discount/MapDiscountCart/?DiscountCode="+this.captureDiscount+"&cartGuid="+this.objCartDetails.CartGuid+"&Action="+action,{}).subscribe(
            data=>{
                if(action == 'Link'){
                    this.DiscountCode = this.captureDiscount;
                    toastr.success('Discount applied successfully');
                }else if (action == 'Delink'){
                    this.captureDiscount = undefined;
                    this.DiscountCode = undefined;
                    toastr.success('Discount removed');
                };
                this.getCartContents();
            },error=>{
                toastr.error(error.json().Message);
            }
        )
    };

    removeDiscount(){ 
        this.captureDiscount = undefined;
        this.DiscountCode = undefined;
    };
    isUserLoggedIn(){
        var boolLoggedIn:boolean = false;
        if( sessionStorage.getItem("isLogedin") && JSON.parse(sessionStorage.getItem("isLogedin")) == true && JSON.parse(sessionStorage.getItem("IsGuestUser")) == false ){
            boolLoggedIn = true;
        }
        return boolLoggedIn;
    }
    public boolTilesorderDeleteChange:boolean=false;
    openDeletTilesorderPopUp(objOrderDetails:OrderDetails,boolTilesorderDeleteChange){
        this.objOrderDetails = new OrderDetails();
        this.objOrderDetails = objOrderDetails;
        this.boolTilesorderDeleteChange = boolTilesorderDeleteChange
    }
    matchEmail($event){
        this.boolIsConfirmEmailEntered = true;
    }
    public boolIsRecaptchaSubmitted:boolean = false;
    confirmCaptcha($event){
        this.boolIsRecaptchaSubmitted = $event;
    }
    confirmDelete($event){
        if( $event ){
            this.deleteTileOrder(this.objOrderDetails,this.checkGuestUser());
        }
    }
    updateGifAD(){
        var isGusetUser:boolean=false;
        var IsGiftAid:boolean;
        if( this.objCartDetails.Id != undefined && this.objCartDetails.Id != ""  ){

            IsGiftAid = this.objCartDetails.IsAccepted;
            isGusetUser = this.checkGuestUser();

            this.objOperationService.put(url.mainUrl+"Cart/UpdateIsGiftAid/?IsGiftAid="+IsGiftAid+"&CartId="+this.objCartDetails.Id+"&IsGuest="+isGusetUser,{}).subscribe(
                data=>{
                    
                },error=>{

                }
            )
        }
    }
    get isRecaptchaSubmitted(){
        return this.boolIsRecaptchaSubmitted;
    }

    get getCutomerId(){
        var customerId:any=this.objCartDetails.CustomerId;
        if( this.objCartDetails.CustomerId == null || this.objCartDetails.CustomerId == undefined || this.objCartDetails.CustomerId =="" ){
            customerId = sessionStorage.getItem("UserId");
        }
        return customerId;
    }

    get getCartId(){
        return this.objCartDetails.Id;
    }

    get getSelectedAddress(){
        return this.arrobjSelectedAddressModel;
    }
    get getDeleteModelName(){
        return this.deleteModelName;
    }

    buildPaymentForm(paymentData,objPaymentModel) {

        if(paymentData.shippingAddress == null){
            paymentData.shippingAddress = Object.assign({},new AddressModel)
        }
        try{
            this.angularForm = this.objFormBuilder.group({
                sitereference:objPaymentModel.Sitereference,
                stprofile:"default",
                currencyiso3a:this.getCurrentCurrency(),
                mainamount:paymentData.cartTotalAmount,
                version:2,
                orderreference:paymentData.CartGuid,
                // billingfirstname:paymentData.billingAddress.CustomerName, 
                billingfirstname:paymentData.customerDetails.FirstName, 
                billinglastname:paymentData.customerDetails.LastName, 
                billingstreet:paymentData.billingAddress.Address1,
                billingpremise:"",
                billingtown:paymentData.billingAddress.City,
                billingcounty:paymentData.billingAddress.StateProvinceModel,
                billingpostcode:paymentData.billingAddress.ZipPostalCode,
                billingtelephone:paymentData.billingAddress.PhoneNumber,
                billingtelephonetype:"W",
                customerfirstname:paymentData.customerDetails.FirstName,
                customerlastname:paymentData.customerDetails.LastName,
                billingcountryiso2a:paymentData.billingAddress.countryModel.Code,
                customerstreet:paymentData.shippingAddress.Address1,
                customertown:paymentData.shippingAddress.City,
                customercounty:paymentData.shippingAddress.StateProvinceModel,
                customerpostcode:paymentData.shippingAddress.ZipPostalCode,
                customertelephone:paymentData.shippingAddress.PhoneNumber,
                customertelephonetype:"H",
                declinedurlredirect:this.paymentCancel,
                successfulurlredirect:this.paymentSuccessUrl, 
                ruleidentifier:"STR-6",
                userConsent:""
            });
        } catch {}
    }
}