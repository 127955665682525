import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { operationService } from '../../_services/operation.service';
import { Lightbox } from 'angular2-lightbox';
import * as url from '../../../environments/environment';
import { setTimeout } from 'core-js/library/web/timers';
declare var OpenSeadragon: any;
import { ProjectTranslationModel } from '../../models/projecttranslationdetail.model';
import { DocumentTypeCodeEnum,ObjectTypeEnum,DocumentTypeCategoryEnum } from '../../models/documenttypecode.enum';
import { ImageDocumentUpload,DocumentProperties,UploadImage,DocumentMapping } from '../../models/documentMapping.model';
import { FMPProjectDetailsModel,FindMyPhoto,LocatePhotoModel,FindmyphotoWithRefereneNumber } from '../../models/project.model';
import { ComponentintractService } from '../../_services/conponentinteraction.service';
@Component({
    selector: 'app-root',
    templateUrl: './photomosaic-gallery.html',
    providers: [operationService]
})
export class PhotomosaicGalleryComponent implements OnInit {
    private _albums: any = [];
    public imagePreviewStatus: boolean = false;
    public clickedImageData: any = [];
    public photomosaicImagePath: string;
    public viewer: any;
    public clickData: any;
    public userEntryCode: any;
    public mainImage: any = [];
    public ProductId: number;
    public previewImage: string;
    public previewImageHeight: any;
    public previewImageWidth: any;
    public notFoundImageStaus:boolean = false;
    public ProjectName:string;
    public projectId:any;
    
    constructor(private objComponentintractService:ComponentintractService,private _lightbox: Lightbox, private _operationService: operationService) {}

    /* new Variables*/
    public objFMPProjectDetailsModel:FMPProjectDetailsModel=new FMPProjectDetailsModel();
    public ObjectTypeEnum : typeof ObjectTypeEnum = ObjectTypeEnum;
    public DocumentTypeCodeEnum : typeof DocumentTypeCodeEnum = DocumentTypeCodeEnum;
    public DocumentTypeCategoryEnum : typeof DocumentTypeCategoryEnum = DocumentTypeCategoryEnum;
    public projectCode:any="";
    public objFMPBannerImage:DocumentMapping = new DocumentMapping();
    public objFMPImage:DocumentMapping = new DocumentMapping();
    public objFindMyPhoto:FindMyPhoto = new FindMyPhoto();
    // public ObjectFindmyphotowithRef:FindmyphotoWithRefereneNumber = new FindmyphotoWithRefereneNumber();
    public boolShowErrorMessage:boolean = false;
    public ThumbnailImagepath:any;

    public x_cordinate:number;
    public y_cordinate:number;
    public audio:any;
    ngOnInit() {
        var arrUrlDetails = window.location.href.split('/');
        this.projectCode = arrUrlDetails[url.urlIndex];
        this.objComponentintractService.setComponentdata({boolLaod:true});
        this.ProjectName = sessionStorage.getItem("ProjectName");
        this.projectId = sessionStorage.getItem("projectId");
        this.activeLanguage = sessionStorage.getItem("activeLanguage");
        this.toggleImageBanner('UP');
        this.getFMPDetails(this.projectCode);
    }
    /*New Implementation Starts*/

    getFMPDetails(projectCode){
        this._operationService.get(url.bricsAPI+"api/Project/GetFMPDetails/?projectCode="+projectCode).subscribe(
            data=>{

                this.objFMPProjectDetailsModel = data;
                if( this.objFMPProjectDetailsModel.IsFMPEnabled ){
                    this.getFMPBanner(data.ProjectId,ObjectTypeEnum.PROJECT,DocumentTypeCategoryEnum.ProjectImage,DocumentTypeCodeEnum.FMPBANNER);
                    this.getFMPImage(data.ProjectId,ObjectTypeEnum.PROJECT,DocumentTypeCategoryEnum.ProjectImage,DocumentTypeCodeEnum.FMPIMAGE);
                } else {
                    this.objComponentintractService.setComponentdata({boolLaod:false});
                }
                this.getDynamicContents(data.ProjectId,this.ObjectTypeEnum.PROJECT,sessionStorage.getItem("languageCode"));
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    getFMPBanner(objectId,ObjectType,documentTypeCategory,documentType){

        var objProjectFilterDocument = new DocumentMapping();
        objProjectFilterDocument.ObjectId = objectId;
        objProjectFilterDocument.ObjectType = ObjectTypeEnum.PROJECT;
        objProjectFilterDocument.languagecode = this.activeLanguage;
        objProjectFilterDocument.DocumentType = documentType;
        objProjectFilterDocument.DocumentTypeCategory = documentTypeCategory;

        this._operationService.post(url.bricsAPI+"DMS/FetchDocuments",objProjectFilterDocument).subscribe(
            data=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
                var arrObjImage = data.filter(item=>item.DocumentType == DocumentTypeCodeEnum.FMPBANNER);
                if( arrObjImage.length > 0 ){

                    this.objFMPBannerImage = arrObjImage[0]
                }
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    getFMPImage(objectId,ObjectType,documentTypeCategory,documentType){
        var objProjectFilterDocument = new DocumentMapping();
        objProjectFilterDocument.ObjectId = objectId;
        objProjectFilterDocument.ObjectType = ObjectTypeEnum.PROJECT;
        objProjectFilterDocument.languagecode = this.activeLanguage;
        objProjectFilterDocument.DocumentType = documentType;
        objProjectFilterDocument.DocumentTypeCategory = documentTypeCategory;

        this._operationService.post(url.bricsAPI+"DMS/FetchDocuments",objProjectFilterDocument).subscribe(
            data=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
                var arrObjImage = data.filter(item=>item.DocumentType == DocumentTypeCodeEnum.FMPIMAGE);
                if( arrObjImage.length > 0 ){
                    this.objFMPImage = arrObjImage[0]
                    this.mainImage = this.objFMPImage.FilePath;
                }
                this.photomosaicImageLoad();
            },error=>{
                this.objComponentintractService.setComponentdata({boolLaod:false});
            }
        )
    }
    locatePhoto(xCordinate,yCordinate,viewportPoint){
        this._operationService.get(url.bricsAPI+"cart/LocatePhoto/?projectId="+this.projectId+"&X="+xCordinate+"&Y="+yCordinate).subscribe(
            data=>{
                this.zoomWithCoOrdinate({
                    PictureLayout_X:data.X,
                    PictureLayout_Y:data.Y,
                    PictureLayout_ImageHeight:data.Height+4,
                    PictureLayout_Width:data.Width+4},false);
                if( data.ImagePath && data.ImagePath != "" ){
                    this.ThumbnailImagepath = data.ImagePath;
                    this.imagePreviewStatus = true;
                }else{
                    this.imagePreviewStatus = false;
                };
            },error=>{
                    this.imagePreviewStatus = false;
            }
        )
    }
    public objMyPhoto:FindMyPhoto = new FindMyPhoto();
    get getProjectName(){
        var projectname="";
        try{
            projectname = this.objProjectTranslationModel.ProjectName;
        } catch{}
        return projectname;
    }
    findMyPhoto(objFindMyPhoto:FindMyPhoto){
        this.boolShowErrorMessage = false;
        this._operationService.post(url.bricsAPI+"Cart/FindMyPhoto",this.objFindMyPhoto).subscribe(
            data=>{
                this.objMyPhoto = data;
                this.ThumbnailImagepath = data.ImagePath;
                this.imagePreviewStatus = true;
                this.zoomWithCoOrdinate({
                    PictureLayout_X:this.objMyPhoto.X,
                    PictureLayout_Y:this.objMyPhoto.Y,
                    PictureLayout_ImageHeight:this.objMyPhoto.Height+4,
                    PictureLayout_Width:this.objMyPhoto.Width+4},true);
            },error=>{
                this.boolShowErrorMessage = true;
                setTimeout(() => {
                    this.boolShowErrorMessage = false;
                }, 3000);
            }
        )
    };
    /*New Implementation Ends*/
    public cordinates:any={}
    public viewPort:any={}
    photomosaicImageLoad() {
        try{
            this.cordinates = {x:1046.666748046875,y:463.4583435058594}
            let that = this;
            
            this.viewer = OpenSeadragon({
                overlays: [{
                    id: 'right-arrow-overlay',
                    x: 0.9955063500828271,
                    y: 0.17277526228602982,
                    placement: 'RIGHT',
                    checkResize: false
                }],
                debugMode:  false,
                defaultZoomLevel:this.objFMPProjectDetailsModel.UIConfigurations.DefaultZoomLevel,
                minZoomLevel:this.objFMPProjectDetailsModel.UIConfigurations.MinimumZoomLevel,
                maxZoomLevel:this.objFMPProjectDetailsModel.UIConfigurations.MaximumZoomLevel,
                id: "openseadragon1",
                prefixUrl: "assets/images/openseadragon/images/",
                tileSources: {
                    type: 'image',
                    url: this.mainImage,
                    buildPyramid: false
                }
            });
            this.viewer.addHandler('canvas-click', function (event) {
                // The canvas-click event gives us a position in web coordinates.
                let webPoint = event.position;

                // Convert that to viewport coordinates, the lingua franca of OpenSeadragon coordinates.
                let viewportPoint = that.viewer.viewport.pointFromPixel(webPoint);

                // Convert from viewport coordinates to image coordinates.
                let imagePoint = that.viewer.viewport.viewportToImageCoordinates(viewportPoint);
                // get zoom label
                let getZoom = Math.round(that.viewer.viewport.getZoom());

                if (getZoom >= 3) {
                    // that.imagePreviewStatus = true; 
                    that.locatePhoto(imagePoint.x,imagePoint.y,viewportPoint);
                } else {
                    that.imagePreviewStatus = false;
                }
            })
        } catch {}
        this.objComponentintractService.setComponentdata({boolLaod:false});
    }

    ngAfterViewInit() {
        let that = this;
        $("div[title='Go home']").click(function () {
            that.imagePreviewStatus = false;
        });
    }
    closePreviewImage() {
        this.imagePreviewStatus = false;
    }
    zoomWithCoOrdinate(UserImageSearch,boolImmidate) {
        var zoomArea = this.viewer.viewport.imageToViewportRectangle(UserImageSearch.PictureLayout_X, UserImageSearch.PictureLayout_Y, UserImageSearch.PictureLayout_ImageHeight, UserImageSearch.PictureLayout_Width);
        this.viewer.viewport.applyConstraints();
        this.viewer.viewport.fitBounds(zoomArea,boolImmidate);
        this.viewer.viewport.applyConstraints();
        this.playAudio();
        this.overlaysHighLights(zoomArea.x,zoomArea.y,zoomArea.height,zoomArea.width);
    }
    playAudio(){

        var audio = new Audio();
        audio.src = "assets/audio/fmpmp3.mp3";
        audio.load();
        audio.play();
    }
    closeImagePreview(){
        this.imagePreviewStatus = false;
    }
    overlaysHighLights(xCordinate,yCordinate,height,width){
        this.viewer.removeOverlay("right-arrow-overlay")
        var elt = document.createElement("div");
        elt.id = "right-arrow-overlay";
        elt.className = "highlight";
        elt.innerHTML = "&nbsp;";
        this.viewer.addOverlay({
            element: elt,
            location: new OpenSeadragon.Rect(xCordinate,yCordinate,height,width)
        });
    }

    public activeLanguage:any;
    public objProjectTranslationModel:ProjectTranslationModel=new ProjectTranslationModel();
    getDynamicContents(objectId,objectType,languagecode){
        this._operationService.get(url.mainUrl+"Project/GetProjectTranslationDetail/?projectId="+objectId+"&LanguageCode="+languagecode).subscribe(
            data=>{
                this.objProjectTranslationModel = data;
            },error=>{

            }
        )
    }
    
    public imageBannerToggleFlag:string;
    toggleImageBanner(flag){
        if( flag == 'DOWN' ){
            $("#toggle_banner").slideUp();
            this.objComponentintractService.setComponentdata({boolMosaicMode:true});
        } else if ( flag == 'UP' ){
            $("#toggle_banner").slideDown();
            this.objComponentintractService.setComponentdata({boolMosaicMode:false});
        }
        this.imageBannerToggleFlag = flag;
    }
}